import styled from "styled-components";

export const ModalContainer = styled.div`
  // background-color: ${(props) => props.theme.bgColor};
  // border: 1px solid ${(props) => props.theme.borderColor};
  width: 100%;
  max-width: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 35px 30px 25px 30px;
  margin-bottom: 10px;
  span {
    font-weight: 600;
    margin-bottom: 35px;
  }
`;

export const ModalBtnContainer = styled.div`
  margin: 35px auto 0 auto;
  width: 90%;
  display: flex;
  justify-items: center;
  justify-content: center;
  flex-direction: row;
  align-items: center;
`;

export const ModalBtn = styled.button`
  // border: none;
  border: ${(props) =>
    props.confirmed ? "none" : `0.5px solid ${props.theme.accent}`};
  border-radius: 3px;
  background-color: ${(props) =>
    props.confirmed ? props.theme.accent : "white"};
  color: ${(props) => (props.confirmed ? "white" : props.theme.accent)};
  text-align: center;
  font-weight: 500;
  padding: 8px;
  cursor: pointer;
  &:first-child {
    margin-right: 30px;
  }
`;

export const modalStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0,0,0,0.7)",
  },
};

export const Row = styled.div`
  display: flex;
  min-height: 35px;
`;

export const RowTitle = styled.p`
  color: ${(props) => props.theme.accent};
  margin-right: 15px;
  width: 100px;
  line-height: 35px;
  font-size: 12px;
`;

export const RowValue = styled.p`
  width: 203.38px;
  line-height: 35px;
  font-size: 12px;
`;
