import styled from "styled-components";
import Footer from "./Footer";
import Header from "./Header";

const Content = styled.main`
  margin: 0 auto;
  margin-top: 60px;
  padding-top: 20px;
  max-width: 930px;
  width: 100%;
  height: auto;
  min-height: 100%;
  padding-bottom: 60px;
  position: relative;
`;

function Layout({ children }) {
  return (
    <>
      <Header />
      <Content>{children}</Content>
      <Footer />
    </>
  );
}

export default Layout;
