import { gql, useMutation } from "@apollo/client";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import useUser from "../../hooks/useUser";
import Button from "../auth/Button";
import FormBox from "../auth/FormBox";
import FormError from "../auth/FormError";
import Input from "../auth/Input";
import { CenterContainer, FatText, getTimestampKST } from "../shared";
import { useState } from "react";
import NumberFormat from "react-number-format";
import { Controller } from "react-hook-form";
import Modal from "react-modal";
import {
  ModalBtn,
  ModalBtnContainer,
  ModalContainer,
  modalStyles,
  Row,
  RowTitle,
  RowValue,
} from "../Modal";

const CREATE_CASH_RECEIPT_MUTATION = gql`
  mutation createCashReceipt(
    $receiverName: String
    $registrationNumber: String!
    $orderName: String!
    $amount: Int!
  ) {
    createCashReceipt(
      receiverName: $receiverName
      registrationNumber: $registrationNumber
      orderName: $orderName
      amount: $amount
    ) {
      ok
      id
      error
    }
  }
`;

const ReceiptContainer = styled(CenterContainer)`
  max-width: 380px;
`;

function CreateReceipt() {
  const location = useLocation();
  const { data: userData } = useUser();
  const INITIAL_STATE = {
    isCompleted: false,
    receiverName: "",
    registrationNumber: "",
    orderName: "",
    amount: "",
  };
  const [submittedValues, setSubmittedValues] = useState(INITIAL_STATE);

  const {
    register,
    handleSubmit,
    errors,
    formState,
    getValues,
    setError,
    clearErrors,
    control,
    setValue,
  } = useForm({
    mode: "onChange",
    defaultValues: {
      receiverName: location?.state?.receiverName || "",
      registrationNumber: location?.state?.registrationNumber || "",
      amount: location?.state?.amount || "",
    },
  });

  const createCashReceiptUpdate = (cache, result) => {
    const { receiverName, registrationNumber, orderName, amount } = getValues();
    const {
      data: {
        createCashReceipt: { ok, id, error },
      },
    } = result;
    if (!ok) {
      return setError("result", {
        message: error,
      });
    }

    if (ok && userData?.me) {
      const newReceipt = {
        __typename: "CashReceipt",
        id,
        receiverName: receiverName ? receiverName : null,
        registrationNumber,
        orderName,
        amount: parseInt(amount.replace(/[^\d]+/g, "").replace("원", "")),
        createdAt: getTimestampKST(),
        canceledAt: null,
        statusType: "SUCCESS",
      };

      const newCacheReceipt = cache.writeFragment({
        data: newReceipt,
        fragment: gql`
          fragment BSName on CashReceipt {
            id
            receiverName
            registrationNumber
            orderName
            amount
            createdAt
            canceledAt
            statusType
          }
        `,
      });
      cache.modify({
        id: "ROOT_QUERY",
        fields: {
          seeCashReceipts(prev) {
            const existed = prev.slice(0, 9);
            return [newCacheReceipt, ...existed];
          },
        },
      });
      cache.modify({
        id: "User:undefined",
        fields: {
          totalCashReceipts(prev) {
            return prev + 1;
          },
        },
      });

      setSubmittedValues({
        isCompleted: true,
        receiverName: receiverName,
        registrationNumber: registrationNumber,
        orderName: orderName,
        amount: amount,
      });
    }
    return;
  };

  const [createCashReceipt, { loading }] = useMutation(
    CREATE_CASH_RECEIPT_MUTATION,
    { update: createCashReceiptUpdate }
  );

  const withValueLimit = ({ value, floatValue }) => {
    if (value == "") {
      return true;
    } else {
      return floatValue > 0;
    }
  };
  // MODAL
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const openModal = () => setModalIsOpen(true);
  const afterOpenModal = () => (document.body.style.overflow = "hidden");
  const afterCloseModal = () => (document.body.style.overflow = "unset");
  const closeModal = () => setModalIsOpen(false);

  const onSubmitValid = (data) => {
    closeModal();
    if (loading) {
      setSubmittedValues({ INITIAL_STATE });
      return;
    }
    let { receiverName, registrationNumber, orderName, amount } = getValues();
    amount = parseInt(amount.replace(/[^\d]+/g, ""));
    registrationNumber = registrationNumber.replace(/-/g, "");

    createCashReceipt({
      variables: { receiverName, registrationNumber, orderName, amount },
    });
  };

  const clearCustomerError = () => {
    // console.log(errors?.registrationNumber?.message);
    clearErrors("result");
  };
  const onSubmitValidAgain = () => {
    setSubmittedValues({ INITIAL_STATE });
  };

  const displayPhone = (value) => {
    if (!value) {
      return "";
    }

    value = value.replace(/[^0-9]/g, "");

    let result = [];
    let restNumber = "";

    // 지역번호와 나머지 번호로 나누기
    if (value.startsWith("02")) {
      // 서울 02 지역번호
      result.push(value.substr(0, 2));
      restNumber = value.substring(2);
    } else if (value.startsWith("1")) {
      // 지역 번호가 없는 경우
      // 1xxx-yyyy
      restNumber = value;
    } else {
      // 나머지 3자리 지역번호
      // 0xx-yyyy-zzzz
      result.push(value.substr(0, 3));
      restNumber = value.substring(3);
    }

    if (restNumber.length === 7) {
      // 7자리만 남았을 때는 xxx-yyyy
      result.push(restNumber.substring(0, 3));
      result.push(restNumber.substring(3));
    } else {
      result.push(restNumber.substring(0, 4));
      result.push(restNumber.substring(4));
    }

    return result.filter((val) => val).join("-");
  };

  let { receiverName, registrationNumber, orderName, amount } = getValues();
  if (registrationNumber) {
    registrationNumber = registrationNumber
      .replace(/-/g, "")
      .replace(/(^02.{0}|^01.{1}|[0-9]{3})([0-9]+)([0-9]{4})/, "$1-$2-$3");
  }

  const modalRows = [
    { title: "이름", value: receiverName || "미등록" },
    { title: "전화번호", value: registrationNumber },
    { title: "주문명", value: orderName },
    { title: "금액", value: amount },
  ];

  return (
    <>
      <ReceiptContainer>
        <FormBox>
          <FatText>
            {submittedValues.isCompleted
              ? "현금영수증 발급 완료"
              : "현금영수증 발급"}
          </FatText>
          <form
            onSubmit={
              submittedValues.isCompleted
                ? handleSubmit(onSubmitValidAgain)
                : handleSubmit(openModal)
            }
          >
            <Row>
              <RowTitle>고객 이름</RowTitle>
              {submittedValues.isCompleted ? (
                <RowValue>
                  {submittedValues.receiverName !== ""
                    ? submittedValues.receiverName
                    : "입력하지 않음"}
                </RowValue>
              ) : (
                <>
                  <Input
                    ref={register({
                      minLength: {
                        value: 2,
                        message: "고객명은 최소 2자 이상입니다.",
                      },
                      maxLength: {
                        value: 20,
                        message: "고객명은 최대 20자 이하입니다.",
                      },
                    })}
                    onChange={clearCustomerError}
                    name="receiverName"
                    type="text"
                    placeholder="2~20자(선택)"
                    hasError={Boolean(errors?.receiverName?.message)}
                  />
                </>
              )}
            </Row>
            <FormError message={errors?.receiverName?.message} />
            <Row>
              <RowTitle>고객 전화번호</RowTitle>
              {submittedValues.isCompleted ? (
                <RowValue>{submittedValues.registrationNumber}</RowValue>
              ) : (
                <Input
                  ref={register({
                    required: "고객 전화번호는 필수입니다.",
                    minLength: {
                      value: 1,
                      message: "고객 전화번호는 필수입니다.",
                    },
                    pattern: {
                      value:
                        /^01([0|1|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})$|^01([0|1|6|7|8|9])-?([0-9]{3})-?([0-9]{5})$|^01([0|1|6|7|8|9])-?([0-9]{4})-?([0-9]{3})$/,
                      message: "휴대폰 번호를 바르게 입력해주세요",
                    },
                  })}
                  onChange={(e) => {
                    console.log(e.target.value);
                    setValue(
                      "registrationNumber",
                      displayPhone(e.target.value)
                    );
                    console.log(e.target.value);
                    clearCustomerError();
                  }}
                  name="registrationNumber"
                  type="text"
                  placeholder="숫자만 입력(필수)"
                  hasError={Boolean(errors?.registrationNumber?.message)}
                />
              )}
            </Row>
            <FormError message={errors?.registrationNumber?.message} />
            <Row>
              <RowTitle>주문명</RowTitle>
              {submittedValues.isCompleted ? (
                <RowValue>{submittedValues.orderName}</RowValue>
              ) : (
                <Input
                  ref={register({
                    required: "주문명을 등록해주세요.",
                    maxLength: {
                      value: 100,
                      message: "최소 1글자 이상 100글자 이하여야 합니다.",
                    },
                  })}
                  onChange={clearCustomerError}
                  name="orderName"
                  type="text"
                  placeholder="1~100자(필수)"
                  hasError={Boolean(errors?.orderName?.message)}
                />
              )}
            </Row>
            <FormError message={errors?.orderName?.message} />
            <Row>
              <RowTitle>발급 금액</RowTitle>
              {submittedValues.isCompleted ? (
                <RowValue>{submittedValues.amount}</RowValue>
              ) : (
                <Controller
                  name="amount"
                  control={control}
                  rules={{ required: "발급 금액을 입력해주세요." }}
                  as={
                    <NumberFormat
                      customInput={Input}
                      thousandSeparator={true}
                      allowNegative={false}
                      isAllowed={withValueLimit}
                      suffix="원"
                      placeholder="숫자만 입력(필수)"
                    />
                  }
                />
              )}
            </Row>
            <FormError message={errors?.amount?.message} />
            <Button
              type="submit"
              value={
                loading
                  ? "Loading..."
                  : submittedValues.isCompleted
                  ? "현금영수증 추가 발급하기"
                  : "현금영수증 발급하기"
              }
              disabled={
                submittedValues.isCompleted
                  ? false
                  : !formState.isValid || loading
              }
            />
            <FormError message={errors?.result?.message} />
          </form>
        </FormBox>
      </ReceiptContainer>
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onAfterClose={afterCloseModal}
        style={modalStyles}
        shouldCloseOnOverlayClick={false}
        contentLabel="Create CashReceipt Modal"
      >
        <ModalContainer>
          <span>현금 영수증을 발급하시겠습니까?</span>
          {modalRows.map((row, index) => (
            <Row key={index}>
              <RowTitle>{row.title}</RowTitle>
              <RowValue>{row.value}</RowValue>
            </Row>
          ))}
          <ModalBtnContainer>
            <ModalBtn type="button" confirmed={false} onClick={closeModal}>
              아니오
            </ModalBtn>
            <ModalBtn type="button" confirmed={true} onClick={onSubmitValid}>
              발급 하기
            </ModalBtn>
          </ModalBtnContainer>
        </ModalContainer>
      </Modal>
    </>
  );
}

export default CreateReceipt;

// https://velog.io/@kingth/react-input-%EA%B0%80%EA%B2%A9%ED%91%9C%EC%8B%9C-3%EC%9E%90%EB%A6%AC-%EB%A7%88%EB%8B%A4-%EC%BD%A4%EB%A7%88

// [JavaScript] 휴대폰번호, 이메일 정규식 체크하기
// https://plitche.github.io/language/javascript/2021-05-30-regularEx/
