import { useReactiveVar } from "@apollo/client";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { isLoggedInVar, logUserOut } from "../apollo";
import useUser from "../hooks/useUser";
import routes from "../routes";
import { FatText } from "../components/shared";

const SHeader = styled.header`
  width: 100%;
  border-bottom: 1px solid #2e394d;
  background-color: ${(props) => props.theme.bgColor};
  padding: 18px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  height: 60px;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
`;

const Wrapper = styled.div`
  max-width: 930px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Column = styled.div``;

const Icon = styled.span`
  margin-left: 15px;
`;

const Button = styled.span`
  background-color: ${(props) => props.theme.accent};
  border-radius: 4px;
  padding: 4px 15px;
  color: white;
  font-weight: 600;
  cursor: pointer;
`;

const IconsContainer = styled.div`
  display: flex;
  align-items: center;
`;

const HeaderLogo = styled.span`
  font-size: 16px;
  color: #2e394d;
  font-weight: 600;
  letter-spacing: 1.1px;
`;

const HeaderName = styled.span`
  color: ${(props) => props.theme.accent};
`;

const HeaderMenu = styled(FatText)`
  font-weight: 600;
  color: ${(props) => props.theme.accent};
`;

function Name({ name }) {
  return name === "" || !name ? null : <HeaderName>{name}</HeaderName>;
}

function Header() {
  const isLoggedIn = useReactiveVar(isLoggedInVar);
  const { data } = useUser();
  return (
    <SHeader>
      <Wrapper>
        <Column>
          <Link to={routes.home}>
            <HeaderLogo>솔톤세무회계 어드민</HeaderLogo>
          </Link>
        </Column>
        <Column>
          {isLoggedIn ? (
            <IconsContainer>
              <Icon>
                <Link to={routes.receipt}>
                  <HeaderMenu>현금영수증</HeaderMenu>
                </Link>
              </Icon>
              <Icon>
                <Name name={data?.me?.name} />
              </Icon>
              <Icon>
                <Button onClick={logUserOut}>로그아웃</Button>
              </Icon>
            </IconsContainer>
          ) : null}
        </Column>
      </Wrapper>
    </SHeader>
  );
}
export default Header;
