import styled from "styled-components";

const SSeparator = styled.div`
  margin: 20px 0px 30px 0px;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  width: 100%;
  align-items: center;
  div {
    width: 100%;
    height: 1px;
    background-color: ${(props) => props.theme.borderColor};
  }
`;

function Separator() {
  return (
    <SSeparator>
      <div></div>
      <div></div>
    </SSeparator>
  );
}
export default Separator;
