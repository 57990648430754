import ReceiptResult from "./ReceiptResult";
import { Table, TableBody, TableHead } from "../Table";
import { CenterContainer, Title } from "../shared";
import styled from "styled-components";
import { gql } from "@apollo/client";
import { useQuery } from "@apollo/client";
import { useState } from "react";

const SEE_RECEIPTS_QUERY = gql`
  query seeCashReceipts($offset: Int!) {
    seeCashReceipts(offset: $offset) {
      id
      receiverName
      registrationNumber
      orderName
      amount
      createdAt
      canceledAt
      statusType
    }
  }
`;

const ME_QUERY = gql`
  query me {
    me {
      totalCashReceipts
    }
  }
`;

const PageControlContainer = styled(CenterContainer)`
  height: 60px;
  display: flex;
  padding-bottom: 30px;
`;

const PageButton = styled.button`
  border: none;
  border-radius: 3px;
  background-color: ${(props) => props.theme.accent};
  color: white;
  text-align: center;
  font-weight: 500;
  padding: 6px;
  width: 70px;
  opacity: ${(props) => (props.disabled ? "0.4" : "1")};
  cursor: ${(props) => (props.disabled ? "default" : "pointer")};
  &:first-child {
    margin-right: 60px;
  }
  &:hover {
    font-weight: 700;
    letter-spacing: 1.1px;
  }
`;

function ReceiptResults() {
  const limit = 10;
  const [page, setPage] = useState(1);
  const offset = (page - 1) * limit;

  const { data: meData } = useQuery(ME_QUERY);
  const { data: receipts } = useQuery(SEE_RECEIPTS_QUERY, {
    variables: { offset },
  });

  const onNextClick = () => setPage(page + 1);
  const onPrevClick = () => (page > 1 ? setPage(page - 1) : page);

  const tableHeaders = [
    "이름",
    "전화번호",
    "금액",
    "주문명",
    "상태",
    "발급시간",
    "취소시간",
  ];
  return (
    <CenterContainer>
      <Title>현금영수증 발급 내역 {meData?.me.totalCashReceipts || 0}건</Title>
      <Table>
        <TableHead>
          <tr>
            {tableHeaders.map((header) => (
              <th key={header}>{header}</th>
            ))}
          </tr>
        </TableHead>
        <TableBody>
          {receipts?.seeCashReceipts.map((receipt) => (
            <ReceiptResult key={receipt.id} {...receipt} />
          ))}
        </TableBody>
      </Table>
      <PageControlContainer>
        <PageButton disabled={page === 1 && true} onClick={onPrevClick}>
          👈 이전
        </PageButton>
        {/* <PageButton
          disabled={meData?.me?.totalCashReceipts - offset <= limit && true}
          onClick={onNextClick}
        > */}
        <PageButton
          disabled={meData?.me.totalCashReceipts - offset <= limit && true}
          onClick={onNextClick}
        >
          다음 👉
        </PageButton>
      </PageControlContainer>
    </CenterContainer>
  );
}

ReceiptResults.propTypes = {};

export default ReceiptResults;

// React로 페이지네이션 UI 구현하기 : https://www.daleseo.com/react-pagination/
