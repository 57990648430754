import styled from "styled-components";

const FooterContainer = styled.footer`
  border-top: 1px solid #2e394d;
  width: 100%;
  height: 60px;
  text-align: center;
  background-color: #00000;
`;

const FooterText = styled.p`
  color: #2e394d;
  font-size: 16px;
  line-height: 60px;
  weight: 600;
  letter-spacing: 1.1px;
`;

function Footer() {
  return (
    <FooterContainer>
      <FooterText>SolTone Tax Admin</FooterText>
    </FooterContainer>
  );
}

export default Footer;
