import { gql, useMutation } from "@apollo/client";
import styled from "styled-components";
import PageTitle from "../components/PageTitle";
import AuthLayout from "../components/auth/AuthLayout";
import FormBox from "../components/auth/FormBox";
import Separator from "../components/Separator";
// import { GoogleLogin } from "react-google-login";
import { logUserIn } from "../apollo";
import { useState } from "react";
import FormError from "../components/auth/FormError";
import { Title } from "../components/shared";
import { GoogleLogin } from "@react-oauth/google";
import { GoogleOAuthProvider } from "@react-oauth/google";
import jwt_decode from "jwt-decode";

const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;
const WHITE_USERS = process.env.REACT_APP_WHITE_USERS;

const LOGIN_MUTATION = gql`
  mutation login($email: String!, $name: String!) {
    login(email: $email, name: $name) {
      ok
      token
      error
    }
  }
`;

// const GoogleLoginButton = styled.button`
//   background-color: ${(props) => props.theme.bgColor};
//   color: ${(props) => props.theme.fontColor};
//   border-color: #2e394d;
//   border-radius: 4px;
//   padding: 12px;
//   cursor: pointer;
//   font-size: 14px;
//   span {
//     margin-left: 10px;
//     font-weight: 600;
//   }
//   &:hover {
//     font-weight: 700;
//     letter-spacing: 1.1px;
//   }
// `;

const Container = styled.div`
  width: 100%;
  max-width: 350px;
  padding-top: 20vh;
  margin: 0 auto;
  height: 95vh;
`;

const verifyEmail = (email) => {
  if (
    WHITE_USERS.includes(email) ||
    email.substr(email.length - 15, 15) === "@soltonetax.com"
  ) {
    return true;
  } else {
    return false;
  }
};

function Login() {
  const [errorMsg, setErrorMsg] = useState("");
  const onError = (error) => {
    setErrorMsg("로그인에 실패했습니다. 다시 시도해주세요.");
  };
  const onCompleted = (data) => {
    const {
      login: { ok, error, token },
    } = data;
    if (!ok) {
      setErrorMsg(`${error}`);
    }
    if (token) {
      logUserIn(token);
    }
  };

  const [login, { loading }] = useMutation(LOGIN_MUTATION, {
    onCompleted,
    onError,
  });

  // const responseGoogle = (response) => {
  //   if (loading) {
  //     setErrorMsg("");
  //     return;
  //   }
  //   console.log(response);
  //   const {
  //     profileObj: { email, name },
  //   } = response;

  //   if (verifyEmail(email)) {
  //     login({ variables: { email, name } }); // call login mutation
  //   } else {
  //     setErrorMsg("솔톤 계정으로 로그인 해주세요");
  //   }
  // };

  const responseGoogleCredential = (credentialResponse) => {
    try {
      const { name, email, email_verified } = jwt_decode(
        credentialResponse.credential
      );

      if (email_verified && verifyEmail(email)) {
        login({ variables: { email, name } }); // call login mutation
      } else {
        setErrorMsg("솔톤 계정으로 로그인 해주세요");
      }
    } catch (e) {
      setErrorMsg("로그인에 실패했습니다. 다시 시도해 주세요");
    }
  };

  return (
    <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
      <AuthLayout>
        <PageTitle title="로그인" />
        <Container>
          <FormBox>
            <Title>로그인 해주세요👇 </Title>
            <Separator />
            {/* <GoogleLogin
            clientId={GOOGLE_CLIENT_ID}
            render={(renderProps) => (
              <GoogleLoginButton
                onClick={renderProps.onClick}
                disabled={renderProps.disabled}
              >
                <FontAwesomeIcon icon={faGoogle} />
                <span>솔톤 계정으로 로그인</span>
              </GoogleLoginButton>
            )}
            buttonText="Login"
            onSuccess={responseGoogle}
            onFailure={responseGoogle}
            cookiePolicy={"single_host_origin"}
          /> */}

            <GoogleLogin
              onSuccess={(credentialResponse) => {
                // console.log(jwt_decode(credentialResponse.credential));
                responseGoogleCredential(credentialResponse);
              }}
              onError={() => {
                console.log("Login Failed");
              }}
              size="large"
            />

            <FormError message={errorMsg} />
          </FormBox>
        </Container>
      </AuthLayout>
    </GoogleOAuthProvider>
  );
}

export default Login;

// https://www.npmjs.com/package/@react-oauth/google
