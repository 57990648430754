import styled from "styled-components";

export const Table = styled.table`
  background-color: #fefefe;
  border-collapse: collapse;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba($color: #000000, $alpha: 0.02);
  // width: 100%;
  // max-width: 930px;
  margin: 2rem auto;
  overflow: hidden;
`;

export const TableHead = styled.thead`
  background-color: #e1e8f2;
  padding: 0 1.5rem;
  tr {
    border-bottom: 1px solid #edeef2;
    padding: 0 1.5rem;
    display: flex;
    th {
      padding: 1rem;
      display: flex;
      align-items: center;
      font-weight: 500;
      text-transform: capitalize;
      width: 120px;
      &:first-child {
        width: 90px;
      }
      &:nth-child(n + 2):nth-child(-n + 3) {
        width: 140px;
      }
      &:nth-child(4) {
        width: 200px;
      }
    }
  }
`;

export const TableBody = styled.tbody`
  padding: 0 1.5rem;
`;

export const TableBodyTR = styled.tr`
  border-bottom: 1px solid #edeef2;
  padding: 0 1.5rem;
  display: flex;
  &:last-child {
    border-bottom: none;
  }
  &:hover {
    color: #ffffff;
    background-color: #fb4f83;
  }
`;

export const TableBodyTD = styled.td`
  padding: 1rem;
  display: flex;
  align-items: center;
  font-weight: 500;
  text-transform: capitalize;
  opacity: ${(props) => (props.isNull || props.status === "CANCEL" ? 0.7 : 1)};
  width: 120px;
  &:first-child {
    width: 90px;
  }
  &:nth-child(n + 2):nth-child(-n + 3) {
    width: 140px;
  }
  &:nth-child(4) {
    width: 200px;
  }
`;
