import { useMutation } from "@apollo/client";
import { gql } from "@apollo/client";
import PropTypes from "prop-types";
import Modal from "react-modal";
import {
  ModalBtn,
  ModalBtnContainer,
  ModalContainer,
  modalStyles,
  Row,
  RowTitle,
  RowValue,
} from "../Modal";
import { getTimestampKST } from "../shared";

const CANCEL_CASH_RECEIPT_MUTATION = gql`
  mutation cancelCashReceipt($id: String!) {
    cancelCashReceipt(id: $id) {
      ok
      id
      error
    }
  }
`;

function CancelReceipt({
  id,
  receiverName,
  registrationNumber,
  amount,
  orderName,
  createdAt,
  modalIsOpen,
  setModalIsOpen,
}) {
  const afterOpenModal = () => (document.body.style.overflow = "hidden");
  const afterCloseModal = () => (document.body.style.overflow = "unset");
  const closeModal = () => setModalIsOpen(false);

  const cancelCashReceiptUpdate = (cache, result) => {
    const {
      data: {
        cancelCashReceipt: { ok, error },
      },
    } = result;
    if (ok) {
      const cashReceiptId = `CashReceipt:${id}`;
      cache.modify({
        id: cashReceiptId,
        fields: {
          canceledAt(prev) {
            return getTimestampKST();
          },
          statusType(prev) {
            return "CANCEL";
          },
        },
      });
    }
  };

  const [cancelCashReceipt, { loading }] = useMutation(
    CANCEL_CASH_RECEIPT_MUTATION,
    {
      update: cancelCashReceiptUpdate,
    }
  );

  const onCancelClick = () => {
    closeModal();
    cancelCashReceipt({ variables: { id } });
  };

  const modalRows = [
    { title: "이름", value: receiverName || "미등록" },
    {
      title: "전화번호",
      value: registrationNumber.replace(
        /(^02.{0}|^01.{1}|[0-9]{3})([0-9]+)([0-9]{4})/,
        "$1-$2-$3"
      ),
    },
    { title: "금액", value: `${amount.toLocaleString("ko-KR")} 원` },
    { title: "주문명", value: orderName },
    { title: "발급일", value: createdAt.replace(/T/, " ").replace(/\..+/, "") },
  ];

  return (
    <Modal
      isOpen={modalIsOpen}
      onAfterOpen={afterOpenModal}
      onAfterClose={afterCloseModal}
      style={modalStyles}
      shouldCloseOnOverlayClick={false}
      contentLabel="Cancel CashReceipt Modal"
    >
      <ModalContainer>
        <span>발급된 현금 영수증을 취소하시겠습니까?</span>
        {modalRows.map((row, index) => (
          <Row key={index}>
            <RowTitle>{row.title}</RowTitle>
            <RowValue>{row.value}</RowValue>
          </Row>
        ))}
        <ModalBtnContainer>
          <ModalBtn type="button" confirmed={false} onClick={closeModal}>
            아니오
          </ModalBtn>
          <ModalBtn type="button" confirmed={true} onClick={onCancelClick}>
            발급 취소 하기
          </ModalBtn>
        </ModalBtnContainer>
      </ModalContainer>
    </Modal>
  );
}

CancelReceipt.prototype = {
  id: PropTypes.string.isRequired,
  receiverName: PropTypes.string,
  registrationNumber: PropTypes.string.isRequired,
  orderName: PropTypes.string.isRequired,
  amount: PropTypes.number.isRequired,
  createdAt: PropTypes.string.isRequired,
};

export default CancelReceipt;
