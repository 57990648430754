import styled from "styled-components";

export const BaseBox = styled.div`
  background-color: ${(props) => props.theme.bgColor};
  border: 1px solid ${(props) => props.theme.borderColor};
  width: 100%;
`;

export const FatLink = styled.span`
  font-weight: 600;
  color: rgb(142, 142, 142);
`;

export const FatText = styled.span`
  font-weight: 600;
`;

export const Title = styled.p`
  size: 14px;
  font-weight: 600;
  margin: 25px auto;
  text-align: center;
`;

export const MainContainer = styled.div`
  width: 100%;
  margin: 0 auto;
  height: auto;
  min-height: 80vh;
`;

export const CenterContainer = styled.div`
  margin: 0 auto;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
`;

export const GetCurrentKST = () => {
  const curDate = new Date();
  const utc = curDate.getTime() + curDate.getTimezoneOffset() * 60 * 1000;
  const timeDiff = 9 * 60 * 60 * 1000;
  const curDateKst = new Date(utc + timeDiff);

  const year = curDateKst.getFullYear().toString().substr(-2);
  const month = curDateKst.getMonth() + 1;
  const day = curDateKst.getDate();
  const hour = curDateKst.getHours();
  const minutes = curDateKst.getMinutes();
  // const second = curDateKst.getSeconds();
  return (
    <FatText>
      {year}-{month < 10 ? `0${month}` : month}-{day < 10 ? `0${day}` : day}{" "}
      {hour < 10 ? `0${hour}` : hour}:{minutes < 10 ? `${minutes}` : minutes}
    </FatText>
  );
};

export const getTimestampKST = () => {
  const today = new Date();
  today.setHours(today.getHours() + 9);
  return today.toISOString();
};
