import {
  ApolloClient,
  createHttpLink,
  InMemoryCache,
  makeVar,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";

const TOKEN = "TOKEN";
// const DARK_MODE = "DARK_MODE";

// local storage의 token 유무로 reactive variables를 만든다 -> 변수를 전역으로 관리할 수 있다.
export const isLoggedInVar = makeVar(Boolean(localStorage.getItem(TOKEN)));

export const logUserIn = (token) => {
  localStorage.setItem(TOKEN, token); // localstorage에 TOKEN이라는 이름으로 totken 저장
  isLoggedInVar(true); // isLoggedInVar라는 reactive variables의 값을 true로 변경
};

export const logUserOut = () => {
  localStorage.removeItem(TOKEN); // localstorage의 TOKEN 삭제
  window.location.reload(); // page reload
};

// Dark/Light Mode
export const darkModeVar = makeVar(false); // 항상 Light 모드로 고정

// export const darkModeVar = makeVar(Boolean(localStorage.getItem(DARK_MODE)));

// export const enableDarkMode = () => {
//   localStorage.setItem(DARK_MODE, "enabled");
//   darkModeVar(true);
// };

// export const disableDarkMode = () => {
//   localStorage.removeItem(DARK_MODE);
//   darkModeVar(false);
// };

// Authentication of Apollo Client
// https://www.apollographql.com/docs/react/networking/authentication

const httpLink = createHttpLink({
  uri:
    process.env.NODE_ENV === "production"
      ? process.env.REACT_APP_SERVER_URI_PROD
      : process.env.REACT_APP_SERVER_URI_DEV,
});

const authLink = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers,
      token: localStorage.getItem(TOKEN),
    },
  };
});

// InMemoryCache를 이용해서 id가 아닌 다른 필드를 고유한 식별자로 지정하기 위해 typePolicies를 사용한다. #12.2 keyFields
// concat() 메서드는 인자로 주어진 배열이나 값들을 기존 배열에 합쳐서 새 배열을 반환
export const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache({
    typePolicies: {
      User: {
        keyFields: (obj) => `User:${obj.username}`,
      },
    },
  }),
});
