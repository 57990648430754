import AuthLayout from "../components/auth/AuthLayout";
import PageTitle from "../components/PageTitle";
import styled from "styled-components";
import { Link } from "react-router-dom";
import routes from "../routes";
import { MainContainer } from "../components/shared";

const HomeNaviContainer = styled.div`
  padding-top: 40px;
  width: 200px;
  height: 200px;
`;

const HomeNaviWrapper = styled.div`
  width: 200px;
  height: 200px;
  background-color: ${(props) => props.theme.bgColor};
  border: 1px solid ${(props) => props.theme.borderColor};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 30px;
  margin-bottom: 10px;
  font-weight: 600;
  letter-spacing: 1.1px;
  color: #2e394d;
  font-size: 16px;
  &:hover {
    color: #ffffff;
    background-color: #fb4f83;
  }
`;

function Home() {
  return (
    <AuthLayout>
      <PageTitle title="홈" />
      <MainContainer>
        <HomeNaviContainer>
          <Link to={routes.receipt}>
            <HomeNaviWrapper>현금영수증</HomeNaviWrapper>
          </Link>
        </HomeNaviContainer>
      </MainContainer>
    </AuthLayout>
  );
}
export default Home;
