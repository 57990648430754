import PageTitle from "../components/PageTitle";
import AuthLayout from "../components/auth/AuthLayout";
import CreateReceipt from "../components/receipt/CreateReceipt";
import Separator from "../components/Separator";
import ReceiptResults from "../components/receipt/ReceiptResults";
import { MainContainer } from "../components/shared";

function Receipt() {
  return (
    <AuthLayout>
      <PageTitle title="현금영수증" />
      <MainContainer>
        <CreateReceipt />
        <Separator />
        <ReceiptResults />
      </MainContainer>
    </AuthLayout>
  );
}
export default Receipt;
