import styled from "styled-components";
import PropTypes from "prop-types";
import { TableBodyTD, TableBodyTR } from "../Table";
import CancelReceipt from "./CancelReceipt";
import { useState } from "react";

const CancelButton = styled.button`
  border: none;
  border-radius: 3px;
  background-color: ${(props) => props.theme.accent};
  color: white;
  text-align: center;
  font-weight: 500;
  padding: 6px 8px;
  cursor: pointer;
  &:hover {
    font-weight: 700;
    letter-spacing: 1.1px;
  }
`;

const StatusIndicator = styled.span`
  display: inline-block;
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  background: #222222;
  margin-right: 0.5rem;
  background: ${(props) => {
    const status = props.status;
    if (status === "SUCCESS") {
      return "#25be64;";
    } else if (status === "CANCEL") {
      return "#dadde4;";
    } else if (status === "RECEIPT") {
      return "#febf02;";
    }
  }};
`;

function ReceiptResult({
  id,
  receiverName,
  registrationNumber,
  orderName,
  amount,
  createdAt,
  canceledAt,
  statusType,
}) {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const openModal = () => setModalIsOpen(true);
  return (
    <>
      <TableBodyTR key={id}>
        <TableBodyTD isNull={!receiverName ? true : false}>
          {receiverName || "미등록"}
        </TableBodyTD>
        <TableBodyTD>
          {registrationNumber.replace(
            /(^02.{0}|^01.{1}|[0-9]{3})([0-9]+)([0-9]{4})/,
            "$1-$2-$3"
          )}
        </TableBodyTD>
        <TableBodyTD>{amount.toLocaleString("ko-KR")} 원</TableBodyTD>
        <TableBodyTD>{orderName}</TableBodyTD>
        <TableBodyTD status={statusType}>
          <StatusIndicator status={statusType}></StatusIndicator>
          {statusType}
        </TableBodyTD>
        <TableBodyTD>
          {createdAt.replace(/T/, " ").replace(/\..+/, "")}
        </TableBodyTD>
        <TableBodyTD>
          {canceledAt ? (
            canceledAt.replace(/T/, " ").replace(/\..+/, "")
          ) : (
            <CancelButton onClick={openModal}>취소하기</CancelButton>
          )}
        </TableBodyTD>
      </TableBodyTR>
      <CancelReceipt
        id={id}
        receiverName={receiverName}
        registrationNumber={registrationNumber}
        amount={amount}
        orderName={orderName}
        createdAt={createdAt}
        modalIsOpen={modalIsOpen}
        setModalIsOpen={setModalIsOpen}
      />
    </>
  );
}

ReceiptResult.propTypes = {
  id: PropTypes.string.isRequired,
  receiverName: PropTypes.string,
  registrationNumber: PropTypes.string.isRequired,
  orderName: PropTypes.string.isRequired,
  amount: PropTypes.number.isRequired,
  createdAt: PropTypes.string.isRequired,
  canceledAt: PropTypes.string,
  statusType: PropTypes.string,
};
export default ReceiptResult;

// https://www.youngslog.kr/graphql/2020/09/10/graphql.html
